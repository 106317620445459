export enum RGLLayout {
  DASHBOARD = "dashboard",
  // SYMBOL = "rgl-symbol-layout",
  // SECTOR = "rgl-sector-layout",
  // DERIVATIVES_TABLE = "rgl-derivatives-table-layout",
  // DERIVATIVES_CHART = "rgl-derivatives-chart-layout",
  // DERIVATIVES_DELIVERY = "rgl-derivatives-delivery-layout",
  // DERIVATIVES_SUPPORT_RESISTANCE = "rgl-derivatives-support-resistance-layout",
  // MISCELLANEOUS = "rgl-miscellaneous-layout",
}
