import type { EmotionCache } from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { SplashScreen } from "components/SplashScreen";
import { AuthConsumer, AuthProvider } from "contexts/auth";
import { SettingsConsumer, SettingsProvider } from "contexts/settings";
import { getAnalytics, logEvent } from "firebase/analytics";
import type { NextPage } from "next";
import type { AppProps } from "next/app";
import Head from "next/head";
import Router from "next/router";
import nProgress from "nprogress";
import type { FC } from "react";
import React from "react";
import { Toaster } from "react-hot-toast";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "store";
import "styles/global.css";
import { createTheme } from "theme";
import { createEmotionCache } from "utils/createEmotionCache";
import "utils/firebase";
import app from "utils/firebase";
import "../node_modules/react-grid-layout/css/styles.css";
import "../node_modules/react-resizable/css/styles.css";

type EnhancedAppProps = AppProps & {
  Component: NextPage;
  emotionCache: EmotionCache;
};

Router.events.on("routeChangeStart", nProgress.start);
Router.events.on("routeChangeError", nProgress.done);
Router.events.on("routeChangeComplete", nProgress.done);

const clientSideEmotionCache = createEmotionCache();

const App: FC<EnhancedAppProps> = (props) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  const getLayout = Component.getLayout ?? ((page) => page);

  React.useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      const analytics = getAnalytics(app);
      logEvent(analytics, "page_view");
    }
  }, []);

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <title>Autotrender</title>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AuthProvider>
            <SettingsProvider>
              <SettingsConsumer>
                {({ settings }) => (
                  <ThemeProvider theme={createTheme(settings)}>
                    <CssBaseline />
                    <Toaster position="top-center" />
                    <AuthConsumer>
                      {(auth) =>
                        !auth.isInitialized ? (
                          <SplashScreen />
                        ) : (
                          getLayout(<Component {...pageProps} />)
                        )
                      }
                    </AuthConsumer>
                  </ThemeProvider>
                )}
              </SettingsConsumer>
            </SettingsProvider>
          </AuthProvider>
        </PersistGate>
      </ReduxProvider>
    </CacheProvider>
  );
};

export default App;
