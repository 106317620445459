import { ThemeOptions } from "@mui/material";

const neutral = {
  100: "#F3F4F6",
  200: "#E5E7EB",
  300: "#D1D5DB",
  400: "#9CA3AF",
  500: "#6C7687",
  600: "#4B5563",
  700: "#374151",
  800: "#1F2937",
  900: "#111827",
};

const background = {
  default: "#FFFFFF",
  secondary: "#FFFFFF",
  paper: "#F8FBFF",
  grid: "#FFFFFF",
  paperSecondary: "#F8FBFF",
  header: "#FFFFFF",
  login: "#FFFFFF",
  light: "#EEF0F5",
  tableHeader: "#EAEEF3",
};

const divider = "#E2E6EE";

const scrollbar = {
  default: "#0074FF",
  secondary: "#BAC1CC",
};

const primary = {
  main: "#0074FF",
  light: "#828DF8",
  dark: "#0A72EF",
  contrastText: "#FFFFFF",
};

const secondary = {
  main: "#6C7687",
  light: "#FFFFFF",
  dark: "#E2E6EE",
  contrastText: "#FFFFFF",
};

const success = {
  main: "#008442",
  light: "#43C6B7",
  dark: "#EAFCF3",
  contrastText: "#FFFFFF",
};

const info = {
  main: "#2196F3",
  light: "#64B6F7",
  dark: "#0B79D0",
  contrastText: "#FFFFFF",
};

const warning = {
  main: "#FFB020",
  light: "#FFBF4C",
  dark: "#B27B16",
  contrastText: "#121828",
};

const error = {
  main: "#E41616",
  light: "#DA6868",
  dark: "#FEEDED",
  contrastText: "#FFFFFF",
};

const text = {
  primary: "#121828",
  secondary: "#65748B",
  tertiary: "#464E5D",
  disabled: "rgba(55, 65, 81, 0.48)",
};

const highlight = {
  main: "#FFA04D",
  light: "#FFB727",
  dark: "#FF9544",
  background: "#fffaf3",
  border: "#ffe3cb",
  contrastText: neutral[900],
  text: "#A75900",
  secondary: "#543a03",
  alt: "#fff6e9",
};

export const lightThemeOptions: ThemeOptions = {
  components: {
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: neutral[500],
          color: "#FFFFFF",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        popper: {
          boxShadow: "0px 5px 14px rgba(100, 116, 139, 0.08)",
          "& *": {
            backgroundColor: "#FFFFFF",
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          "&.MuiChip-filledDefault": {
            backgroundColor: "transparent",
            "& .MuiChip-deleteIcon": {
              color: neutral[500],
            },
          },
          "&.MuiChip-outlinedDefault": {
            borderColor: neutral[700],
            "& .MuiChip-deleteIcon": {
              color: neutral[700],
            },
          },
        },
      },
      variants: [
        {
          props: { variant: "up" },
          style: {
            backgroundColor: "#EAFCF3",
            color: "#008442",
            "& .MuiChip-icon": {
              fill: "#008442",
            },
          },
        },
        {
          props: { variant: "down" },
          style: {
            backgroundColor: "#FEEDED",
            color: "#E41616",
            "& .MuiChip-icon": {
              fill: "#E41616",
            },
          },
        },
      ],
    },
    MuiCssBaseline: {
      styleOverrides: {
        "#nprogress .bar": {
          backgroundColor: "#0074FF",
        },
        ".simplebar-scrollbar:before": {
          background: "#CCD1DB",
        },
        "*::-webkit-scrollbar": { width: "7px", height: "7px", backgroundColor: "#EEF0F5" },
        "*::-webkit-scrollbar-thumb": {
          width: "7px",
          height: "150px",
          borderRadius: "1px",
          backgroundColor: "#CCD1DB",
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        input: {
          fontWeight: 500,
          caretColor: "#0074FF",
        },
        root: {
          backgroundColor: "#F9F9FA",
          border: "1px solid",
          borderColor: "#E2E6EE",
          "&:hover:not(.Mui-disabled)": {
            border: "1px solid",
            borderColor: "#E2E6EE",
            backgroundColor: "#F9F9FA",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          "&::placeholder": {
            opacity: 1,
            color: text.secondary,
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          color: text.secondary,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: divider,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderColor: divider,
          borderStyle: "solid",
          borderWidth: 1,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderColor: divider,
          borderStyle: "solid",
          borderWidth: 1,
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: neutral[500],
        },
        track: {
          backgroundColor: neutral[400],
          opacity: 1,
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFFFFF",
          "& .MuiTableRow-root:nth-of-type(odd)": {
            backgroundColor: "#F9F9FA",
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${divider}`,
        },
        footer: {
          color: "#121828",
          borderColor: divider,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFFFFF",
          ".MuiTableCell-root": {
            color: "#65748B",
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        arrow: {
          color: "#F3F4F6",
        },
        tooltip: {
          backgroundColor: "#FFFFFF",
          color: "#121828",
          boxShadow: "0px 0px 15px rgba(31, 41, 55, 0.20)",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontWeight: 500,
        },
      },
    },
  },
  palette: {
    action: {
      active: neutral[500],
      focus: "rgba(55, 65, 81, 0.12)",
      hover: "rgba(55, 65, 81, 0.04)",
      selected: "rgba(55, 65, 81, 0.08)",
      disabledBackground: "rgba(55, 65, 81, 0.12)",
      disabled: "rgba(55, 65, 81, 0.26)",
    },
    background,
    divider,
    scrollbar,
    error,
    info,
    mode: "light",
    neutral,
    primary,
    secondary,
    success,
    text,
    warning,
    highlight,
  },
  shadows: [
    "none",
    "0px 5px 14px rgba(100, 116, 139, 0.08)",
    "0px 1px 1px rgba(100, 116, 139, 0.06), 0px 1px 2px rgba(100, 116, 139, 0.1)",
    "0px 0px 4px rgba(100, 116, 139, 0.24)",
    "0px 1px 4px rgba(100, 116, 139, 0.12)",
    "0px 1px 5px rgba(100, 116, 139, 0.12)",
    "0px 1px 6px rgba(100, 116, 139, 0.12)",
    "0px 2px 6px rgba(100, 116, 139, 0.12)",
    "0px 3px 6px rgba(100, 116, 139, 0.12)",
    "0px 2px 4px rgba(31, 41, 55, 0.06), 0px 4px 6px rgba(100, 116, 139, 0.12)",
    "0px 5px 12px rgba(100, 116, 139, 0.12)",
    "0px 5px 14px rgba(100, 116, 139, 0.08)",
    "0px 5px 15px rgba(100, 116, 139, 0.12)",
    "0px 6px 15px rgba(100, 116, 139, 0.12)",
    "0px 7px 15px rgba(100, 116, 139, 0.12)",
    "0px 8px 15px rgba(100, 116, 139, 0.12)",
    "0px 9px 15px rgba(100, 116, 139, 0.12)",
    "0px 10px 15px rgba(100, 116, 139, 0.12)",
    "0px 12px 22px -8px rgba(100, 116, 139, 0.25)",
    "0px 13px 22px -8px rgba(100, 116, 139, 0.25)",
    "0px 14px 24px -8px rgba(100, 116, 139, 0.25)",
    "0px 10px 10px rgba(31, 41, 55, 0.04), 0px 20px 25px rgba(31, 41, 55, 0.1)",
    "0px 25px 50px rgba(100, 116, 139, 0.25)",
    "0px 25px 50px rgba(100, 116, 139, 0.25)",
    "0px 25px 50px rgba(100, 116, 139, 0.25)",
  ],
};
