import { ThemeOptions } from "@mui/material";

// Colors

const neutral = {
  100: "#F3F4F6",
  200: "#E5E7EB",
  300: "#D1D5DB",
  400: "#9CA3AF",
  500: "#6C7687",
  600: "#4B5563",
  700: "#374151",
  800: "#1F2937",
  900: "#1B2027",
};

const background = {
  default: "#121419",
  secondary: "#161A20",
  paper: "#121419",
  grid: "#161A20",
  paperSecondary: "#1B2027",
  header: "#121419",
  login: "#161A20",
  light: "#22272F",
  tableHeader: "#282E36",
};

const divider = "#22272F";

const scrollbar = {
  default: "#4499FF",
  secondary: "#38414E",
};

const primary = {
  main: "#4499FF",
  light: "#909BEF",
  dark: "#5BA6FF",
  contrastText: neutral[900],
};

const secondary = {
  main: "#6C7687",
  light: "#FFFFFF",
  dark: "#30353E",
  contrastText: neutral[900],
};

const success = {
  main: "#0EDB67",
  light: "#43C6B7",
  dark: "#152c26",
  contrastText: neutral[900],
};

const info = {
  main: "#2196F3",
  light: "#64B6F7",
  dark: "#0B79D0",
  contrastText: neutral[900],
};

const warning = {
  main: "#FFB020",
  light: "#FFBF4C",
  dark: "#B27B16",
  contrastText: neutral[900],
};

const error = {
  main: "#FF4C4C",
  light: "#DA6868",
  dark: "#301E23",
  contrastText: neutral[900],
};

const text = {
  primary: "#EDF2F7",
  secondary: "#6C7687",
  tertiary: "#A7B1C5",
  disabled: "rgba(255, 255, 255, 0.48)",
};

const highlight = {
  main: "#FFA04D",
  light: "#FFB727",
  dark: "#FF9544",
  background: "#211e1b",
  border: "#593b25",
  contrastText: neutral[900],
  text: "#FF9544",
  secondary: "#afa48d",
  alt: "#292217",
};

export const darkThemeOptions: ThemeOptions = {
  components: {
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: neutral[500],
          color: "#FFFFFF",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        popper: {
          "& *": {
            backgroundColor: "#282E36",
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          "&.MuiChip-filledDefault": {
            backgroundColor: "transparent",
            "& .MuiChip-deleteIcon": {
              color: neutral[500],
            },
          },
          "&.MuiChip-outlinedDefault": {
            borderColor: neutral[700],
            "& .MuiChip-deleteIcon": {
              color: neutral[700],
            },
          },
        },
      },
      variants: [
        {
          props: { variant: "up" },
          style: {
            backgroundColor: "#152C26",
            color: "#0EDB67",
            "& .MuiChip-icon": {
              fill: "#0EDB67",
            },
          },
        },
        {
          props: { variant: "down" },
          style: {
            backgroundColor: "#301E23",
            color: "#FF4C4C",
            "& .MuiChip-icon": {
              fill: "#FF4C4C",
            },
          },
        },
      ],
    },
    MuiCssBaseline: {
      styleOverrides: {
        "#nprogress .bar": {
          backgroundColor: "#4499FF",
        },
        ".simplebar-scrollbar:before": {
          background: "#282E36",
        },
        "*::-webkit-scrollbar": { width: "7px", height: "7px", backgroundColor: "#161A20" },
        "*::-webkit-scrollbar-thumb": {
          width: "7px",
          height: "150px",
          borderRadius: "3px",
          backgroundColor: "#282E36",
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          border: "1px solid #273240",
          "&:hover:not(.Mui-disabled)": {
            backgroundColor: "#1B2027",
            border: "1px solid #475260",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          // Customizing the chrome autofill input colors
          "&:-webkit-autofill": {
            WebkitBoxShadow: `0 0 0 100px ${background.default} inset !important`,
            WebkitTextFillColor: `${text.primary} !important`,
          },
          "&::placeholder": {
            opacity: 1,
            color: text.secondary,
          },
          "&:hover": {
            borderColor: "#475260",
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          color: text.secondary,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: divider,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderColor: divider,
          borderStyle: "solid",
          borderWidth: 1,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderColor: divider,
          borderStyle: "solid",
          borderWidth: 1,
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: neutral[700],
        },
        track: {
          backgroundColor: neutral[500],
          opacity: 1,
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          "& .MuiTableRow-root:nth-of-type(odd)": {
            backgroundColor: "#1B2027",
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${divider}`,
        },
        footer: {
          color: "#EDF2F7",
          borderColor: divider,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          ".MuiTableCell-root": {
            backgroundColor: "#161A20",
            color: "#6C7687",
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        arrow: {
          color: "#262D37",
        },
        tooltip: {
          backgroundColor: "#262D37",
          color: "#E2E6EE",
        },
      },
    },
  },
  palette: {
    action: {
      active: neutral[500],
      hover: "rgba(255, 255, 255, 0.04)",
      selected: "rgba(255, 255, 255, 0.08)",
      disabledBackground: "rgba(255, 255, 255, 0.12)",
      disabled: "rgba(255, 255, 255, 0.26)",
    },
    background,
    divider,
    scrollbar,
    error,
    info,
    mode: "dark",
    neutral,
    primary,
    secondary,
    success,
    text,
    warning,
    highlight,
  },
  shadows: [
    "none",
    "none",
    "0px 1px 2px rgba(0, 0, 0, 0.24)",
    "0px 3px 6px #0B0D11AB",
    "0px 1px 4px rgba(0, 0, 0, 0.24)",
    "0px 1px 5px rgba(0, 0, 0, 0.24)",
    "0px 1px 6px rgba(0, 0, 0, 0.24)",
    "0px 2px 6px rgba(0, 0, 0, 0.24)",
    "0px 3px 6px rgba(0, 0, 0, 0.24)",
    "0px 4px 6px rgba(0, 0, 0, 0.24)",
    "0px 5px 12px rgba(0, 0, 0, 0.24)",
    "0px 5px 14px rgba(0, 0, 0, 0.24)",
    "0px 5px 15px rgba(0, 0, 0, 0.24)",
    "0px 6px 15px rgba(0, 0, 0, 0.24)",
    "0px 7px 15px rgba(0, 0, 0, 0.24)",
    "0px 8px 15px rgba(0, 0, 0, 0.24)",
    "0px 9px 15px rgba(0, 0, 0, 0.24)",
    "0px 10px 15px rgba(0, 0, 0, 0.24)",
    "0px 12px 22px -8px rgba(0, 0, 0, 0.24)",
    "0px 13px 22px -8px rgba(0, 0, 0, 0.24)",
    "0px 14px 24px -8px rgba(0, 0, 0, 0.24)",
    "0px 20px 25px rgba(0, 0, 0, 0.24)",
    "0px 25px 50px rgba(0, 0, 0, 0.24)",
    "0px 25px 50px rgba(0, 0, 0, 0.24)",
    "0px 25px 50px rgba(0, 0, 0, 0.24)",
  ],
};
