import { ThemeOptions } from "@mui/material";

export const baseThemeOptions: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1280,
      xl: 1920,
    },
  },
  components: {
    MuiAvatar: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 600,
          letterSpacing: 0,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            padding: "4px",
          },
          "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
            minWidth: "auto",
            width: "auto",
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: "4px",
        },
        outlined: {
          borderRadius: "2px",
        },
        sizeSmall: {
          padding: "6px 16px",
        },
        sizeMedium: {
          padding: "8px 20px",
        },
        sizeLarge: {
          padding: "11px 24px",
        },
        textSizeSmall: {
          padding: "7px 12px",
        },
        textSizeMedium: {
          padding: "9px 16px",
        },
        textSizeLarge: {
          padding: "12px 16px",
        },
        endIcon: {
          position: "absolute",
          right: 15,
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: "16px 24px",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "32px 24px",
          "&:last-child": {
            paddingBottom: "32px",
          },
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: "h6",
        },
        subheaderTypographyProps: {
          variant: "body2",
        },
      },
      styleOverrides: {
        root: {
          padding: "32px 24px",
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: "primary",
      },
      styleOverrides: {
        root: {
          padding: "0px 4px",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: "2px",
          fontWeight: 600, // 500 in dark 600 in light
          fontSize: "14px",
          height: "26px",
          position: "relative",
          padding: "4px 0px",
        },
        // This is done for the MuiChip-icon to be on the right of the MuiChip-label
        icon: {
          position: "absolute",
          right: "-6px",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        "*": {
          boxSizing: "border-box",
        },
        html: {
          MozOsxFontSmoothing: "grayscale",
          WebkitFontSmoothing: "antialiased",
          display: "flex",
          flexDirection: "column",
          minHeight: "100%",
          width: "100%",
        },
        body: {
          display: "flex",
          flex: "1 1 auto",
          flexDirection: "column",
          minHeight: "100%",
          width: "100%",
        },
        "#__next": {
          display: "flex",
          flex: "1 1 auto",
          flexDirection: "column",
          height: "100%",
          width: "100%",
        },
        "#nprogress": {
          pointerEvents: "none",
        },
        "#nprogress .bar": {
          height: 3,
          left: 0,
          position: "fixed",
          top: 0,
          width: "100%",
          zIndex: 2000,
        },
        ".simplebar-scrollbar.simplebar-visible:before": {
          opacity: 1,
        },
        ".react-resizable-handle.react-resizable-handle-se": {
          opacity: 0,
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        input: {
          padding: "8px",
          "&::placeholder": {
            fontSize: "13px",
          },
        },
        root: {
          "&:after": {
            borderBottom: "0",
          },
          "&:before": {
            borderBottom: "0",
          },
          borderBottom: "0",
          "&:hover:not(.Mui-disabled)": {
            "&:after": {
              borderBottom: "0",
            },
            "&:before": {
              borderBottom: "0",
            },
            borderBottom: "0",
          },
          borderRadius: "3px",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          padding: 8,
        },
        sizeSmall: {
          padding: 4,
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: "hidden",
        },
      },
    },
    MuiLink: {
      defaultProps: {
        color: "inherit",
        underline: "hover",
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          marginRight: "16px",
          "&.MuiListItemIcon-root": {
            minWidth: "unset",
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          padding: 0,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "4px",
        },
        input: {
          fontWeight: 500,
          borderRadius: "4px",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: "none",
        },
      },
    },
    MuiPopover: {
      defaultProps: {
        elevation: 16,
      },
    },
    MuiRadio: {
      defaultProps: {
        color: "primary",
      },
      styleOverrides: {
        root: {
          padding: "0px 4px",
          "& .MuiSvgIcon-root": {
            width: "14px",
          },
          "& .MuiFormControlLabel-label": {
            fontSize: "13px",
          },
        },
      },
    },
    MuiSwitch: {
      defaultProps: {
        color: "primary",
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 500,
          lineHeight: 1.71,
          minWidth: "auto",
          paddingLeft: 0,
          paddingRight: 0,
          textTransform: "none",
          "& + &": {
            marginLeft: 24,
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          minWidth: 277,
          borderRadius: "4px",
          overflowY: "scroll",
          // This is to prevent double borders at the bottom of the table
          "& .MuiTableBody-root .MuiTableRow-root:last-child .MuiTableCell-root": {
            borderBottom: "0",
          },
          "& .MuiTableFooter-root .MuiTableRow-root:last-child .MuiTableCell-root": {
            borderBottom: "0",
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          whiteSpace: "nowrap",
          padding: "0px 12px",
          height: "38px",
          fontSize: "12px",
        },
        body: {
          fontSize: "13px",
        },
        footer: {
          fontSize: "16px",
          fontWeight: 600,
          padding: "0px 12px",
          borderTop: "1px solid",
          height: "38px",
        },
        head: {
          padding: "0px 12px",
          height: "38px",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        scrollButtons: {
          width: "16px",
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          "& .MuiTableCell-paddingCheckbox": {
            paddingTop: 4,
            paddingBottom: 4,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: "3px",
          padding: "10px 12px",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        body1: {
          fontSize: "14px",
        },
        subtitle1: {
          fontSize: "15px",
        },
      },
      variants: [
        {
          props: { variant: "link" },
          style: {
            fontSize: "13px",
            textDecoration: "none",
            color: "inherit",
            cursor: "pointer",
            "&:hover": {
              textDecoration: "underline",
            },
          },
        },
      ],
    },
  },
  direction: "ltr",
  shape: {
    borderRadius: 8,
  },
  typography: {
    button: {
      fontWeight: 600,
    },
    fontSize: 14,
    fontFamily:
      'Inter, "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    body1: {
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: 1.5,
    },
    body2: {
      fontSize: "13px",
      fontWeight: 400,
      lineHeight: 1.57,
    },
    subtitle1: {
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: 1.75,
    },
    subtitle2: {
      fontSize: "0.875rem",
      fontWeight: 500,
      lineHeight: 1.57,
    },
    overline: {
      fontSize: "0.75rem",
      fontWeight: 600,
      letterSpacing: "0.5px",
      lineHeight: 2.5,
      textTransform: "uppercase",
    },
    caption: {
      fontSize: "0.75rem",
      fontWeight: 400,
      lineHeight: 1.66,
    },
    h1: {
      fontWeight: 700,
      fontSize: "3.5rem",
      lineHeight: 1.375,
    },
    h2: {
      fontWeight: 700,
      fontSize: "3rem",
      lineHeight: 1.375,
    },
    h3: {
      fontWeight: 700,
      fontSize: "2.25rem",
      lineHeight: 1.375,
    },
    h4: {
      fontWeight: 700,
      fontSize: "2rem",
      lineHeight: 1.375,
    },
    h5: {
      fontWeight: 600,
      fontSize: "1.5rem",
      lineHeight: 1.375,
    },
    h6: {
      fontWeight: 600,
      fontSize: "1.125rem",
      lineHeight: 1.375,
    },
  },
  zIndex: {
    appBar: 1200,
    header: 1150,
    drawer: 1100,
  },
};
