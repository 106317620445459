import React from "react";
import type { FC, ReactNode } from "react";
import PropTypes from "prop-types";
import { authApi } from "api/auth";
import { ChanagePasswordResponse, ChangePasswordRequest, LoginResponse, Status, User } from "types/auth";
import { LoginCredentials } from "types/auth";
import toast from "react-hot-toast";
import axios from "axios";

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
}

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

type InitializeAction = {
  type: "INITIALIZE";
  payload: { isAuthenticated: boolean; user: User | null };
};
type LoginAction = { type: "LOGIN"; payload: { user: User } };
type LogoutAction = { type: "LOGOUT" };

type Action = InitializeAction | LoginAction | LogoutAction;

const handlers: Record<string, (state: State, action: Action) => State> = {
  // @ts-ignore
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user } = action.payload;
    return { ...state, isAuthenticated, isInitialized: true, user };
  },
  // @ts-ignore
  LOGIN: (state: State, action: LoginAction): State => {
    const { user } = action.payload;
    return { ...state, isAuthenticated: true, user };
  },
  LOGOUT: (state: State): State => ({ ...state, isAuthenticated: false, user: null }),
};

const reducer = (state: State, action: Action): State =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

interface AuthContextValue extends State {
  platform: "JWT";
  login: (credentials: LoginCredentials) => Promise<LoginResponse>;
  logout: () => Promise<void>;
  changePassword: (values: ChangePasswordRequest) => Promise<ChanagePasswordResponse>;
}

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthContext = React.createContext<AuthContextValue>({
  ...initialState,
  platform: "JWT",
  login: () => Promise.resolve({} as LoginResponse),
  logout: () => Promise.resolve(),
  changePassword: () => Promise.resolve({} as ChanagePasswordResponse),
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = React.useReducer(reducer, initialState);

  React.useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        const isAuthenticated =
          JSON.parse(window.localStorage.getItem("isAuthenticated") as string) || false;
        const user = JSON.parse(window.localStorage.getItem("user") as string) || null;
        if (isAuthenticated && user) {
          dispatch({ type: "INITIALIZE", payload: { isAuthenticated, user } });
        } else {
          dispatch({ type: "INITIALIZE", payload: { isAuthenticated: false, user: null } });
        }
      } catch (err) {
        console.error(err);
        dispatch({ type: "INITIALIZE", payload: { isAuthenticated: false, user: null } });
      }
    };
    initialize();
  }, []);

  const login = async (values: LoginCredentials): Promise<LoginResponse> => {
    try {
      const loginResponse = await authApi.login(values);
      if (loginResponse.status === Status.SUCCESS) {
        const { data: user } = loginResponse;
        localStorage.setItem("isAuthenticated", JSON.stringify(true));
        localStorage.setItem("user", JSON.stringify(loginResponse.data));
        dispatch({ type: "LOGIN", payload: { user } });
      }
      return loginResponse;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        toast.error(error.response.data.message || "Something went wrong");
        return error.response.data as LoginResponse;
      }
      return {} as LoginResponse;
    }
  };

  const logout = async (): Promise<void> => {
    try {
      await authApi.logout();
    } catch (error) {
      console.error(error);
    } finally {
      localStorage.removeItem("isAuthenticated");
      localStorage.removeItem("user");
      dispatch({ type: "LOGOUT" });
    }
  };

  const changePassword = async (values: ChangePasswordRequest): Promise<ChanagePasswordResponse> => {
    try {
      const res = await authApi.changePassword(values);
      if (res.status == 1) {
        dispatch({ type: "LOGOUT" });
        return res;
      } else {
        toast.error(res.msg);
        return {} as ChanagePasswordResponse;
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        toast.error(error.response?.data?.msg || "Something went wrong");
        return error.response?.data as ChanagePasswordResponse;
      }
      return {} as ChanagePasswordResponse;
    }
  }

  return (
    <AuthContext.Provider value={{ ...state, platform: "JWT", login, logout, changePassword }}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const AuthConsumer = AuthContext.Consumer;
