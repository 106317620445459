import { createApi, fetchBaseQuery, FetchBaseQueryMeta } from "@reduxjs/toolkit/query/react";
import toast from "react-hot-toast";

async function throwAsyncError(meta: FetchBaseQueryMeta) {
  throw { url: meta.request.url, method: meta.request.method };
}

export const api = createApi({
  reducerPath: "api",
  baseQuery: async (args, api, extraOptions) => {
    const baseQuery = fetchBaseQuery({
      baseUrl: process.env.NEXT_PUBLIC_API_URL,
      credentials: "include",
    });
    let result = await baseQuery(args, api, extraOptions);
    /*
     * If response is 401, that means the user is not authenticated.
     * In that case, we redirect to the login page.
     */
    if (result.meta?.response?.status === 401) {
      toast.error("Your session is expired, please login again", { id: "SESSION_EXPIRE" });
      localStorage.removeItem("user");
      localStorage.removeItem("isAuthenticated");
      location.href = "/login";
    }

    /*
     * Mapping every 204 No Content response to an error.
     * This helps in preventing an empty response from being treated as valid response.
     */
    if (result.meta?.response?.status === 204) {
      return { error: { status: "CUSTOM_ERROR", error: "No Data" } };
    }
    try {
      if ((result.meta?.response?.status as number) > 204) {
        throwAsyncError(result.meta as FetchBaseQueryMeta);
      }
    } catch (error) {
      console.error(error);
    } finally {
      return result;
    }
  },
  endpoints: () => ({}),
});
