import { FC } from "react";
import { Box } from "@mui/material";
import { LogoWithText } from "./LogoWithText";

export const SplashScreen: FC = () => {
  return (
    <Box
      sx={{
        alignItems: "center",
        backgroundColor: "neutral.900",
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        justifyContent: "center",
        left: 0,
        p: 3,
        position: "fixed",
        top: 0,
        width: "100vw",
        zIndex: "modal",
      }}
    >
      <Box height="60" width="60" position="relative">
        <LogoWithText color="white" height="150" width="150" />
      </Box>
    </Box>
  );
};
