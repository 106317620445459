export enum Status {
  SUCCESS = "success",
  FAILED = "failed",
}

export interface LoginCredentials {
  username: string;
  password: string;
}

export type LoginResponse =
  | {
      message: string;
      status: Status.SUCCESS;
      data: User;
    }
  | {
      message: string;
      status: Status.FAILED;
      data: null;
    };

export interface LogoutResponse {
  message: string;
  status: Status;
}

export interface User {
  username: string;
  daysLeft: string;
}

export interface ForgetPasswordRequest {
  EmailID: string;
}

export interface ForgetPasswordResponse {
  responseCode: number;
  status: number;
  success: boolean;
  msg: string;
  userId: string;
}

export interface ChangePasswordRequest {
  userId: string;
  oldPassword: string;
  newPassword: string;
}

export type ChanagePasswordResponse = {
      msg: string;
      status: number;
      responseCode: number;
      success: boolean;
    }

