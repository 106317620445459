import { Definition } from "./common";

export enum Widgets {
  dashboard_niftyDataIndicator = "dashboard/niftyDataIndicator",
  dashboard_bankNiftyDataIndicator = "dashboard/bankNiftyDataIndicator",
  dashboard_callOptionDataIndicator = "dashboard/callOptionDataIndicator",
  dashboard_putOptionDataIndicator = "dashboard/putOptionDataIndicator",
  dashboard_trendMidTermChecklist = "dashboard/trendMidTermChecklist",
  dashboard_niftyTrendChecklist = "dashboard/niftyTrendChecklist",
  dashboard_bankNiftyTrendChecklist = "dashboard/bankNiftyTrendChecklist",
  dashboard_sectorStrengthRanking = "dashboard/sectorStrengthRanking",
  dashboard_indexSentiments = "dashboard/indexSentiments",
  dashboard_marketBalancer = "dashboard/marketBalancer",

  symbol_indicators = "symbol/indicators",
  symbol_supportResistance = "symbol/supportResistance",
  symbol_sentiments = "symbol/sentiments",
  symbol_intraday = "symbol/intraday",
  symbol_callPut = "symbol/callPut",
  symbol_oiConcentration = "symbol/oiConcentration",
  symbol_oiLatest = "symbol/oiLatest",
  symbol_oiSecond = "symbol/oiSecond",

  sector_stopLossTarget = "sector/stopLossTarget",
  sector_sectorStrengthRanking = "sector/sectorStrengthRanking",
  sector_pivotPoints = "sector/pivotPoints",

  derivatives_callPut = "derivatives/callPut",
  derivatives_intraday = "derivatives/intraday",
  derivatives_trend = "derivatives/trend",

  derivatives_oiConcentration = "derivatives/oiConcentration",
  derivatives_changeInTodayOI = "derivatives/changeInTodayOI",

  derivatives_support = "derivatives/support",
  derivatives_resistance = "derivatives/resistance",

  miscellaneous_sentiments = "miscellaneous/sentiments",

  mustWatch_fnoRanking = "mustWatch/fnoRanking",
  mustWatch_nifty50Ranking = "mustWatch/nifty50Ranking",
  mustWatch_stocksPositional = "mustWatch/stocksPositional",

  stockAnalysis_delivery = "stockAnalysis/delivery",
  stockAnalysis_deliveryAvgScanner = "stockAnalysis/deliveryAvgScanner",
}

export type WidgetDetails = {
  videoLink?: string;
  definition?: Definition;
};
