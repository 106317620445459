import type { Theme } from "@mui/material";
import { createTheme as createMuiTheme } from "@mui/material/styles";
import { baseThemeOptions } from "theme/baseThemeOptions";
import { darkThemeOptions } from "theme/darkThemeOptions";
import { lightThemeOptions } from "theme/lightThemeOptions";
import { Settings } from "types/settings";
import { getFontSizeOptions } from "./fontSizeOptions";

interface Neutral {
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
}

interface Scrollbar {
  default: string;
  secondary: string;
}

interface Highlight {
  text: string;
  contrastText: string;
  background: string;
  main: string;
  light: string;
  dark: string;
  border: string;
  secondary: string;
  alt: string;
}

/*
  Material UI allows module augmentation.
  This is a workaround to allow us to augment the theme with
  our own theme options.
*/

// Augment the base theme with our own theme options.
declare module "@mui/material/styles" {
  interface Palette {
    neutral: Neutral;
    scrollbar: Scrollbar;
    highlight: Highlight;
  }
  interface PaletteOptions {
    neutral: Neutral;
    scrollbar: Scrollbar;
    highlight: Highlight;
  }
}

declare module "@mui/material/styles/zIndex" {
  interface ZIndex {
    header: number;
  }
}

// Augment the Typography component with our own variants.
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    link: true;
  }
}

// Augment the Chip component with our own variants
declare module "@mui/material/Chip" {
  interface ChipPropsVariantOverrides {
    up: true;
    down: true;
  }
}

declare module "@mui/material" {
  interface TypeBackground {
    default: string;
    secondary: string;
    paper: string;
    grid: string;
    paperSecondary: string;
    header: string;
    login: string;
    light: string;
    tableHeader: string;
  }
}

export const createTheme = (settings: Settings): Theme => {
  const theme = createMuiTheme(
    baseThemeOptions,
    settings.theme === "dark" ? darkThemeOptions : lightThemeOptions,
    getFontSizeOptions(settings.fontSize)
  );

  return theme;
};
