import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { Layouts } from "react-grid-layout";
import { Widgets } from "types/widgets";
import defaultLayout from "layouts/default.json";
import { RGLLayout } from "types/layouts";
import mapLayout from "utils/mapLayout";

const ROW_HEIGHT = 38;
const MARGIN: [number, number] = [8, 0];

interface AppState {
  deliveryAverageScannerPercentage: string;
  supportResistancePercentage: string;
  favoriteStocks: { symbol: string; sector: string }[];
  highlightedWidgets: string[];
  rowHeight: number;
  margin: [number, number];
  defaultProfile: { layout: Layouts; name: string };
  currentProfile: { layout: Layouts; name: string };
  profiles: { layout: Layouts; name: string }[];
  defaultCols: number;
}

const widgetBlacklist = [
  Widgets.dashboard_trendMidTermChecklist,
  Widgets.dashboard_bankNiftyTrendChecklist,
  Widgets.dashboard_niftyTrendChecklist,
  Widgets.dashboard_indexSentiments,
  Widgets.dashboard_marketBalancer,
];

const defaultDashboardLayout = mapLayout(defaultLayout[RGLLayout.DASHBOARD], ROW_HEIGHT, MARGIN[1]);
Object.keys(defaultDashboardLayout).forEach((key) => {
  defaultDashboardLayout[key] = defaultDashboardLayout[key].filter((w) =>
    widgetBlacklist.every((widget) => widget !== w.i)
  );
});
const defaultProfile = { layout: defaultDashboardLayout, name: "Default Dashboard" };

const initialState: AppState = {
  deliveryAverageScannerPercentage: "60",
  supportResistancePercentage: "0.5",
  favoriteStocks: [],
  rowHeight: ROW_HEIGHT,
  margin: MARGIN,
  highlightedWidgets: [],
  defaultProfile,
  currentProfile: defaultProfile,
  profiles: [defaultProfile],
  defaultCols: 8,
};

const slice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setDeliveryAverageScannerPercentage(state: AppState, action: PayloadAction<string>): void {
      state.deliveryAverageScannerPercentage = action.payload;
    },
    setSupportResistancePercentage(state: AppState, action: PayloadAction<string>): void {
      state.supportResistancePercentage = action.payload;
    },
    addFavoriteStock(
      state: AppState,
      action: PayloadAction<{ symbol: string; sector: string }>
    ): void {
      state.favoriteStocks.push(action.payload);
    },
    removeFavoriteStock(
      state: AppState,
      action: PayloadAction<{ symbol: string; sector: string }>
    ): void {
      state.favoriteStocks = state.favoriteStocks.filter(
        (stock) => stock.symbol !== action.payload.symbol
      );
    },
    toggleHighlight(state: AppState, action: PayloadAction<string>): void {
      const index = state.highlightedWidgets.indexOf(action.payload);
      if (index === -1) {
        state.highlightedWidgets.push(action.payload);
      } else {
        state.highlightedWidgets.splice(index, 1);
      }
    },
    addProfile(state: AppState, action: PayloadAction<{ layout: Layouts; name: string }>): void {
      if (state.profiles.find((profile) => profile.name === action.payload.name)) {
        return;
      }
      state.profiles.push(action.payload);
      state.currentProfile = action.payload;
    },
    setCurrentProfile(
      state: AppState,
      action: PayloadAction<{ layout: Layouts; name: string }>
    ): void {
      state.currentProfile = action.payload;
    },
    removeProfile(state: AppState, action: PayloadAction<string>): void {
      state.profiles = state.profiles.filter((profile) => profile.name !== action.payload);
      state.currentProfile = defaultProfile;
    },
  },
});

export const { reducer } = slice;

export default slice.actions;
