import { ThemeOptions } from "@mui/material";

export function getFontSizeOptions(fontSize: number): ThemeOptions {
  return {
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          ".apexcharts-text": {
            fontSize: fontSize - 0.5,
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          body1: { fontSize },
          body2: { fontSize: fontSize - 1 },
        },
        variants: [
          {
            props: { variant: "link" },
            style: {
              fontSize,
              textDecoration: "none",
              color: "inherit",
              cursor: "pointer",
              "&:hover": {
                textDecoration: "underline",
              },
            },
          },
        ],
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            "& .MuiTableCell-root": {
              fontSize: fontSize - 1,
            },
          },
        },
      },
    },
  };
}
