import { Layouts } from "react-grid-layout";

export default function mapLayout(layouts: Layouts, R: number, My: number) {
  const newLayouts: Layouts = {};
  for (const key in layouts) {
    newLayouts[key] = layouts[key].map((layout) => {
      const x = layout.h;
      const y = (R * x - Math.max(0, x - 1) * My + My) / R + 0.3;
      return {
        ...layout,
        h: y,
        y: layout.y - 0.3,
        minH: y,
        maxH: y,
        minW: 2,
      };
    });
  }
  return newLayouts;
}
