import axios from "axios";
import {
  ChanagePasswordResponse,
  ChangePasswordRequest,
  ForgetPasswordRequest,
  ForgetPasswordResponse,
  LoginCredentials,
  LoginResponse,
  LogoutResponse,
} from "types/auth";

const baseUrl = process.env.NEXT_PUBLIC_API_URL;

export const authApi = {
  login: async (credentials: LoginCredentials): Promise<LoginResponse> => {
    const res = await axios.post(`${baseUrl}/Login/Login`, credentials, { withCredentials: true });
    return res.data;
  },
  logout: async (): Promise<LogoutResponse> => {
    const { data } = await axios.delete(`${baseUrl}/Logout`, { withCredentials: true });
    return data;
  },
  changePassword: async (request: ChangePasswordRequest): Promise<ChanagePasswordResponse> => {
    const res = await axios.post(`${baseUrl}/changepassword`, request, { withCredentials: true });
    return res.data;
  },
  forgotPassword: async (request: ForgetPasswordRequest): Promise<ForgetPasswordResponse> => {
    const res = await axios.post(`${baseUrl}/ForgetPassword`, request, { withCredentials: true });
    return res.data;
  },
  resetPassword: async (request: ChangePasswordRequest): Promise<any> => {
    const res = await axios.post(`${baseUrl}/ChangePassword`, request, { withCredentials: true });
    return res.data;
  },
};
