export enum FontSize {
  SMALL = 14,
  MEDIUM = 15,
  LARGE = 16,
}

export enum TickersView {
  MARQUEE = "marquee",
  LIST = "list",
}

export interface Settings {
  fontSize: FontSize;
  theme: "light" | "dark";
  tickersView: TickersView;
}
